import { useState } from 'react';
import Icon from '../Icon';

interface ToggleSidebarButtonProps {
  onToggle: (isVisible: boolean) => void;
}

const ToggleSidebarButton = ({ onToggle }: ToggleSidebarButtonProps) => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);

  const handleToggle = () => {
    setIsSidebarVisible((prev) => {
      const newVisibility = !prev;
      onToggle(newVisibility);
      return newVisibility;
    });
  };

  return (
    <button
      onClick={handleToggle}
      className="hidden items-center gap-2.5 self-end py-2.5 pb-5 pt-0 text-sm font-bold text-primary lg:flex"
    >
      {isSidebarVisible ? (
        <>
          <Icon name="FaChevronRight" className="size-4" />
          Hide support panel
        </>
      ) : (
        <>
          <Icon name="FaChevronLeft" className="size-4" />
          Show support panel
        </>
      )}
    </button>
  );
};

export default ToggleSidebarButton;
