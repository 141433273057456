import * as NavigationMenu from '@radix-ui/react-navigation-menu';

// Array footer menu
export const menuItems = [
  { label: 'Support', link: '' },
  { label: 'About DARTS', link: '' },
] as const;

export default function Footer() {
  return (
    <footer className="mt-auto bg-secondary px-8">
      <div className="container mx-auto">
        <div className="flex flex-col justify-between gap-8 lg:flex-row-reverse lg:items-center lg:gap-6">
          <img className="my-7 w-[173px]" src="https://uikit.wfp.org/cdn/logos/latest/wfp-logo-standard-white-en.svg" />
          <NavigationMenu.Root data-cy="footerNavigation">
            <NavigationMenu.List className="flex flex-col gap-8 lg:flex-row lg:items-center lg:gap-6">
              {menuItems.map((item) => {
                return (
                  <NavigationMenu.Item data-cy="footerNavigationItem" key={item.label} className="font-bold text-white">
                    <NavigationMenu.Link href={item.link} className="leading-[30px]">
                      {item.label}
                    </NavigationMenu.Link>
                  </NavigationMenu.Item>
                );
              })}
            </NavigationMenu.List>
          </NavigationMenu.Root>
        </div>
        <div data-cy="footerDate" className="mt-16 border-t border-white py-6 font-medium text-white lg:mt-0 lg:py-3">
          {new Date().getFullYear()} © World Food Programme
        </div>
      </div>
    </footer>
  );
}
