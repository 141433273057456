import ReactDOM from 'react-dom/client';
import 'react-toastify/dist/ReactToastify.css';
import '@/styles/index.css';
import RoutesController from './RoutesController.tsx';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import * as Sentry from '@sentry/react';
import config from './config.ts';
import { ToastCloseButton } from '@/components/ToastCloseButton/index.tsx';
import queryClient from './lib/queryClient.ts';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: config.SENTRY_DSN,
    environment: process.env.NODE_ENV,
    integrations: [Sentry.replayIntegration()],

    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <RoutesController />
      <ToastContainer position="bottom-right" closeButton={ToastCloseButton} autoClose={false} />
      <ReactQueryDevtools initialIsOpen={false} buttonPosition="top-left" />
    </QueryClientProvider>
  </BrowserRouter>,
);
