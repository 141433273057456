import { cn } from '@/utils/classnames';
import { VariantProps, cva } from 'class-variance-authority';
import React, { useState, useRef, useEffect, ComponentPropsWithoutRef } from 'react';
import { Heading } from '../Heading';
import ToggleSidebarButton from '../ToggleSidebarButton';

export interface SectionProps extends VariantProps<typeof variants>, ComponentPropsWithoutRef<'section'> {
  title?: string;
  children: React.ReactNode | React.ReactNode[] | JSX.Element | JSX.Element[] | string;
  sidebar?: React.ReactNode | React.ReactNode[] | JSX.Element | JSX.Element[] | string;
}

export const sectionVariants = {
  default: 'bg-white',
  gray: 'bg-light-gray',
  centered: 'flex items-center justify-center',
};

const variants = cva('p-8', {
  variants: {
    variant: sectionVariants,
  },
  defaultVariants: {
    variant: 'default',
  },
});

export const Section = ({ title, variant, children, sidebar, ...rest }: SectionProps) => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const [sidebarHeight, setSidebarHeight] = useState('auto');
  const [isAnimating, setIsAnimating] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const sidebarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentRef.current && sidebarRef.current) {
      const contentHeight = contentRef.current.offsetHeight;
      setSidebarHeight(`${contentHeight}px`);
    }
  }, [children, sidebar]);

  const handleToggle = (visibility: boolean) => {
    setIsAnimating(true);
    setIsSidebarVisible(visibility);
    setTimeout(() => setIsAnimating(false), 300);
  };

  return sidebar ? (
    <section {...rest}>
      <div className="container mx-auto lg:flex lg:flex-col">
        <ToggleSidebarButton onToggle={handleToggle} />
        <div
          className={cn(
            'flex flex-col lg:flex-row transition-all duration-300 ease-in-out overflow-hidden',
            isSidebarVisible ? 'gap-4' : 'gap-0',
          )}
        >
          <div
            ref={contentRef}
            className={cn(
              `w-full p-6 mb-auto transition-all duration-300 ease-in-out`,
              isSidebarVisible ? 'lg:w-2/3' : 'lg:w-full',
              variants({ variant }),
            )}
          >
            {title && <Heading>{title}</Heading>}
            <div {...(title && { className: 'mt-6' })}>{children}</div>
          </div>
          <aside
            ref={sidebarRef}
            style={isAnimating ? { height: sidebarHeight } : undefined}
            className={cn(
              'w-full lg:w-1/3 transition-all duration-300 ease-in-out',
              isSidebarVisible ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-full lg:w-0',
              isAnimating && `overflow-hidden`,
            )}
          >
            {sidebar}
          </aside>
        </div>
      </div>
    </section>
  ) : (
    <section {...rest} className={cn(variants({ variant }), rest.className)}>
      <div className="container mx-auto">
        {title && <Heading>{title}</Heading>}
        <div {...(title && { className: 'mt-6' })}>{children}</div>
      </div>
    </section>
  );
};
